import { Link } from "react-router-dom";
import arrowicon from "../img/arrowicon.png";

function Overviewproject(props) {
  return (
    <div className="overviewproject">
      <div className="container">
        <h2>
          Overview <span>of Project</span>
        </h2>
        <p>{props.description}</p>
        <div className="projecdeslist">
          <div className="protechnology">
            <h3>
              Technology <span>Used</span>
            </h3>
            <ul>
              {props.techList.map((data, i) => {
                return <li key={i + 2}>{data}</li>;
              })}
            </ul>
          </div>

          <div className="protechnology">
            <h3>
              What <span>we did</span>
            </h3>
            <ul>
              {props.workedList.map((data, i) => {
                return <li key={i + 3}>{data}</li>;
              })}
            </ul>
          </div>
          <div className="visitsitebnt">
            <a href={props.siteURL}>
              Visit Site <img src={arrowicon} alt="img" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Overviewproject;
