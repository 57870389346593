import BenefitIcon1 from "../img/benifiticon1.svg";
import BenefitIcon2 from "../img/benifit_icon2.svg";
import BenefitIcon3 from "../img/benifit_icon3.svg";
import BenefitIcon4 from "../img/benifit_icon4.svg";
import BenefitIcon5 from "../img/benifit_icon5.svg";
import BenefitIcon6 from "../img/benifit_icon6.svg";
function Benefithiring() {
  return (
    <div className="benefitofhiring">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="blueheading mb-2">
              <span className="redtext">Benefits of Hiring Developers</span>{" "}
              Through IT Offshore Solutions
            </h2>
            <ul>
              <li>
                <img src={BenefitIcon1} alt="img" />
                <p>
                  Access and work with the top software engineers in the IT
                  industry.
                </p>
              </li>

              <li>
                <img src={BenefitIcon2} alt="img" />
                <p>
                  Improve your project’s productivity and performance from Day
                  1.
                </p>
              </li>

              <li>
                <img src={BenefitIcon3} alt="img" />
                <p>
                  Work with a flexible business partner that offers custom
                  solutions.
                </p>
              </li>

              <li>
                <img src={BenefitIcon4} alt="img" />
                <p>
                  Reduce inherent project risks and avoid the high costs of an
                  in-house team.
                </p>
              </li>

              <li>
                <img src={BenefitIcon5} alt="img" />
                <p>
                  Boost your product’s security with the considerable of our
                  senior engineers.
                </p>
              </li>

              <li>
                <img src={BenefitIcon6} alt="img" />
                <p>
                  Start your project immediately and quickly get an edge over
                  the competition.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Benefithiring;
