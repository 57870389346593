import Searchheading from "../elements/Searchheading";
import Developerresult from "../elements/Developerresult";

function SearchDev() {
  return (
    <div className="">
      <Searchheading />
      <Developerresult />
    </div>
  );
}
export default SearchDev;
