import React from "react";

export default [
  {
    id: 1,
    priceTitle: "Individual Developer",
    devPrice: "30-50",
    aboutDev: [
      "1-3 years of experience",
      "Mostly worked on a single stack",
      "Top Individual Developer",
      "Junior Developers",
    ],
  },
  {
    id: 2,
    priceTitle: "Multi-Faceted Developer",
    devPrice: "50-80",
    aboutDev: [
      "3-7 years of experience",
      "Expertise in multiple stacks",
      "Worked on niche skills",
      "Mentors Junior Developers",
    ],
  },
  {
    id: 3,
    priceTitle: "Leader & Diverse Developer",
    devPrice: "80+",
    aboutDev: [
      "7-10+ years of experience",
      "Worked across stacks, products",
      "Multiple niche skills",
      "Led multiple teams in the past",
    ],
  },
];
