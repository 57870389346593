import footlogo from "../img/footlogo.svg";
import skype from "../img/skype.svg";
import facebookicon from "../img/facebookicon.svg";
import instagram from "../img/instagram.svg";
import twittericon from "../img/twittericon.svg";
import linkedicon from "../img/linkedicon.svg";
import goTopImg from "../img/go-top.svg";
import { useRef } from "react";
import { Link } from "react-router-dom";
function Footer() {
  const scrollref = useRef();
  const scrolltotop = () => {
    if (window !== undefined) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footleft">
                <img className="img-fluid" src={footlogo} alt="img" />
                <p>
                  A OffShore stays ahead of the curve with digital marketing
                  trends. Our success has us leading the pack amongst.
                </p>
              </div>
            </div>

            <div className="col-md-4 d-flex justify-content-md-center">
              <div className="footlink">
                <h2>Company</h2>
                <ul>
                  <li>
                    <Link to="/hiredeveloper">- Hire Developers</Link>
                  </li>
                  <li>
                    <Link to="/portfolio">- Portfolio</Link>
                  </li>
                  <li>
                    <Link to="/aboutus">- About Us</Link>
                  </li>
                  <li>
                    <a href="https://itoffshoresolutions.com/blog/">- Blog</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4 d-flex justify-content-md-center">
              <div className="getintouch">
                <h2>Get In Touch</h2>
                <ul>
                  {/* <li>
                    <a href="https://www.facebook.com/itoffshoresolutions/">
                      <img src={skype} alt="img" />
                    </a>
                  </li> */}
                  <li>
                    <a href="https://www.facebook.com/itoffshoresolutions/">
                      <img src={facebookicon} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/itoffshoresolutions/">
                      <img src={instagram} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/itoffshoresolut">
                      <img src={twittericon} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/itoffshoresolutions">
                      <img src={linkedicon} alt="img" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          &copy; 2023 itoffshoresolutions. All rights reserved.
        </div>
      </footer>
      <button className="go_top_btn" ref={scrollref} onClick={scrolltotop}>
        <img src={goTopImg} alt="Go to top" />
      </button>
    </>
  );
}
export default Footer;
