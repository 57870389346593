import React from "react";

export default function CompareAnalysis() {
  return (
    <>
      <section className="analysis_sec">
        <div className="container">
          <h2 className="blueheading">
            Comparative <span className="redtext">Analysis</span>{" "}
          </h2>
          <p>
            We provide tailored, robust, scalable and secure Android development
            solutions that delight users and deliver engagement.
          </p>
          <div className="analysis_table">
            <table width="100%" cellPadding="0" className="table">
              <thead>
                <tr>
                  <th width="25%"></th>
                  <th width="25%">FutureProfilez Software Solutions</th>
                  <th width="25%">In-House</th>
                  <th width="25%">Freelance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Time to get right developers</td>
                  <td>1 day - 2 weeks</td>
                  <td>4 - 12 weeks</td>
                  <td>1 - 12 weeks</td>
                </tr>
                <tr>
                  <td>Time to start a project</td>
                  <td>1 day - 2 weeks</td>
                  <td>2 - 10 weeks</td>
                  <td>1 - 10 weeks</td>
                </tr>
                <tr>
                  <td>Recurring cost of training & benefits</td>
                  <td>-</td>
                  <td>$10,000 - $25,000</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Time to scale size of team</td>
                  <td>48 hours - 1 week</td>
                  <td>4 - 16 weeks</td>
                  <td>1 - 12 weeks</td>
                </tr>
                <tr>
                  <td>Pricing (weekly average)</td>
                  <td>1.5X</td>
                  <td>2X</td>
                  <td>1X</td>
                </tr>
                <tr>
                  <td>Project failure risk</td>
                  <td>Extremely low, we have 98% success ratio</td>
                  <td>Low</td>
                  <td>Very High</td>
                </tr>
                <tr>
                  <td>Developers backed by a delivery team</td>
                  <td>Yes</td>
                  <td>Some</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>Dedicated resources</td>
                  <td>Yes</td>
                  <td>-</td>
                  <td>Some</td>
                </tr>
                <tr>
                  <td>Quality Guarantee </td>
                  <td>Yes</td>
                  <td>High</td>
                  <td>High</td>
                </tr>
                <tr>
                  <td>Assured work rigor</td>
                  <td>Yes</td>
                  <td>Some</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>Tools and professional environment</td>
                  <td>Yes</td>
                  <td>High</td>
                  <td>Uncertain</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
