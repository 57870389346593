import bluearrow from '../img/bluearrow.svg';
import { NavLink } from "react-router-dom";

function Servlist(props) {
  return (
    <li>
      <img src={props.img} alt={props.servicetitle} />
      <h2>{props.servicetitle}</h2>
      <p>{props.servicedescription}</p>
      <div className="viewdeveloper">
        <NavLink to="/searchdev">
          View Developers <img src={bluearrow} alt="img" />
        </NavLink>
      </div>
    </li>
  );
}


export default Servlist;