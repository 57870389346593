import awardimg01 from "../img/awardimg01.svg";
import awardimg02 from "../img/awardimg02.svg";
import awardimg03 from "../img/awardimg03.svg";
import awardimg04 from "../img/awardimg04.svg";
function Awardwining() {
    return(
        <div className="awardwining">
            <div className="container">
                <h2 className="blueheading"><span className="redtext">Award-Winning</span> Outsourcing IT Company</h2>
                <p className="awardtitle">We are proud to showcase our awards and accolades in the IT industry for software development.</p>
                <div className="awardwininglogos">
                    <ul>
                        <li><img src={awardimg01} alt="img" /></li>
                        <li><img src={awardimg02} alt="img" /></li>
                        <li><img src={awardimg03} alt="img" /></li>
                        <li><img src={awardimg04} alt="img" /></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Awardwining;