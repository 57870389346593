import React from "react";
import DevCatePrice from "../api/DevCatePrice";
import TeamPriceComp from "./TeamPriceComp";

function Teamprice() {
  return (
    <>
      <div className="findprice">
        <h2 className="blueheading">
          <span className="redtext">Find The Perfect Developer</span> For Your{" "}
          <br /> Team At The Right Price
        </h2>
        <div className="container">
          <div className="row">
            {DevCatePrice.map((data, i) => {
              return (
                <div className="col-md-6 col-lg-4" key={data.id}>
                  <TeamPriceComp
                    title={data.priceTitle}
                    price={data.devPrice}
                    abputDev={data.aboutDev}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Teamprice;
