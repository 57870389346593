function Portfoliodetailhead(props) {
  return (
    <div>
      <div className="portfoliodetailbanner">
        <div className="container">
          <h2>{props.title}</h2>
        </div>
      </div>
      <div className="container">
        <div className="portdetailbanner">
          <img src={props.siteImg} alt="img" />
        </div>
      </div>
    </div>
  );
}
export default Portfoliodetailhead;
