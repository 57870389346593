import manlaptop from "../img/manlaptop.png";
function Whychoose() {
    return (
      <div className="whychoose">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="why_choose_cnt">
                <h2 className="blueheading">
                  Why choose IT Offshore for{" "}
                  <span className="redtext">hiring website Developers</span>
                </h2>
                <p>
                  Hire dedicated developers, and programmers to work on your
                  ideas/ projects with technical consultation throughout the
                  development lifecycle, for any industry. Take a look at these
                  reasons why your business should hire our developers:
                </p>
                <ul>
                  <li>Flexible client engagement models</li>
                  <li>24/7 technical support and communication</li>
                  <li>Quality assurance</li>
                  <li>Cost-effective web developers solution</li>
                  <li>Integration with different platform</li>
                </ul>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="mainlap">
                <img className="img-fluid" src={manlaptop} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

}
export default Whychoose;