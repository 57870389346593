import React, { useState } from "react";
import { Link } from "react-router-dom";
import arrow from "../img/arrow-wh.svg";
import lookgreatimg01 from "../img/lookgreatimg01.svg";
import HireDevForm from "./HireDevForm";

function Lookgreat() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <div className="lookgreadtsec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="lookgreatcnt">
                <h2>
                  Look great on <br />
                  <span className="redtext">the web</span>
                </h2>
                <p>
                  Itoffshore Solutions is the leading web development company
                  unlocking technology for your business. We help our clients do
                  amazing things in their unique style. Our work brings jaw
                  dropping first impressions and outstanding results on second
                  look.
                </p>
                <div className="consult">
                  <button className="bluebtn bluebg" onClick={handleShow}>
                    Consult our experts <img src={arrow} alt="img" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="lookgreatimg">
                <img className="img-fluid" src={lookgreatimg01} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <HireDevForm label="request" show={show} handleClose={handleClose} />
    </>
  );
}

export default Lookgreat;
