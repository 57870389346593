import React from "react";
import Homebanner from "../elements/Mainbanner";
import Ourservice from "../elements/Ourservice";
import Industries from "../elements/Industries";
import Getstartproject from "../elements/Getstartproject";
import Hiredeveloper from "../elements/Hiredeveloper";
import Whychoose from "../elements/Whychoose";
import Recruiterfounder from "../elements/Recruiterfounder";
import Submitcv from "../elements/Submitcv";
import Awardwining from "../elements/Awardwining";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>
          Offshore Software Development Company India | Offshore IT Services
        </title>
        <meta
          name="title"
          content="Offshore Software Development Company India | Offshore IT Services"
        />
        <meta
          name="description"
          content="We are a top IT Offshore Software Solutions Providers as we have served thousands of satisfied clients from all over the globe for various Offshore Software Development Services.We are a Top IT Offshore Outsourcing Company. Offers Complete Web Development, IoT Services, Online IT Services, provides Mobile App Development, at affordable prices. Talk to us now on +91-9983333334"
        />
        <meta
          name="keywords"
          content="IT Offshore, Offshore Company, Offshore Software Developers, Offshore Development Company, offshore it"
        />
      </Helmet>
      <Homebanner />
      <Ourservice />
      <Industries />
      <Getstartproject />
      <Hiredeveloper />
      <Whychoose />
      <Recruiterfounder />
      <Submitcv />
      <Awardwining />
    </>
  );
}

export default Home;
