import commenticonyellow from "../img/commenticonyellow.svg";
function Devhiretime() {
    return (
      <div className="hiretime">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="hiretimeheading">
                Hire Developers Within 72 Hours!
              </h2>
              <p className="hiretitle">
                Our simple process gets you working with the right freelancer
                quickly and seamlessly
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="hireprocess">
                <div className="hireprocessicon">
                  <img
                    className="img-fluid"
                    src={commenticonyellow}
                    alt="img"
                  />
                </div>
                <div className="hireprocesscnt">
                  <h3>TELL US YOUR REQUIREMENTS</h3>
                  <p>
                    Share your requirement/ idea with us, Our Solutions Experts
                    spend time with you to understand the specifics of what your
                    ideal candidate looks like, so they can understand your
                    technical needs and business objectives.
                  </p>
                </div>
                <div className="processcount">01</div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="hireprocess">
                <div className="hireprocessicon">
                  <img
                    className="img-fluid"
                    src={commenticonyellow}
                    alt="img"
                  />
                </div>
                <div className="hireprocesscnt">
                  <h3>CHECK FOR A FIT</h3>
                  <p>
                    You interact with the candidates to ensure alignment with
                    your product goals, Kick- off after evaluating the
                    candidates for a fit & aligning with them on our mutual
                    goals.
                  </p>
                </div>
                <div className="processcount">02</div>
              </div>
            </div>

            <div className="col-md-12 col-lg-6">
              <div className="hireprocess">
                <div className="hireprocessicon">
                  <img
                    className="img-fluid"
                    src={commenticonyellow}
                    alt="img"
                  />
                </div>
                <div className="hireprocesscnt">
                  <h3>GET PERSONALISED RECOMMENDATIONS</h3>
                  <p>
                    We will handpick the top 1-3 best-suited candidates for your
                    project and introduce you to them within 7 days so you can
                    save time.
                  </p>
                </div>
                <div className="processcount">03</div>
              </div>
            </div>

            <div className="col-md-12 col-lg-6">
              <div className="hireprocess">
                <div className="hireprocessicon">
                  <img
                    className="img-fluid"
                    src={commenticonyellow}
                    alt="img"
                  />
                </div>
                <div className="hireprocesscnt">
                  <h3>MEET YOUR PRODUCT GOALS</h3>
                  <p>
                    objectives are met. A manager will be assigned to you, He
                    will provide support you both you and the freelancer
                    throughout the duration of the engagement and make sure that
                    you get what you want out of it.
                  </p>
                </div>
                <div className="processcount">04</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Devhiretime;