import connectimg from '../img/connectimg.svg'
import discuss from '../img/discuss.svg'
import createimg from '../img/createimg.svg'
import getdoteimg from '../img/getdoteimg.svg'
function Getstartproject() {
    return (
      <div className="projectstartsec">
        <div className="projectstartbluesec">
          <h2>Get your Project started within 24 hours</h2>
          <p>
            We are the largest, globally-distributed network of top business,
            design, and technology talent,
            <br /> ready to tackle your most important initiatives.
          </p>
        </div>
        <div className="projectsteps">
          <div className="container">
            <div className="getdoteleft">
              <img src={getdoteimg} alt="img" />
            </div>
            <div className="getdoteright">
              <img src={getdoteimg} alt="img" />
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="prostepbox">
                  <div className="stepcount">1</div>
                  <img src={connectimg} alt="img" />
                  <h3>Connect</h3>
                  <p>
                    Get connected to the ideal developer who specializes in the
                    latest technologies and frameworks.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="prostepbox">
                  <div className="stepcount">2</div>
                  <img src={discuss} alt="img" />
                  <h3>Discuss</h3>
                  <p>
                    Once you finalized the candidate, discuss your project
                    requirements thoroughly with the selected developer.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="prostepbox">
                  <div className="stepcount">3</div>
                  <img src={createimg} alt="img" />
                  <h3>Create</h3>
                  <p>
                    Once the developer agrees with all your terms, you are all
                    set to get started and experience top-notch results.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Getstartproject;