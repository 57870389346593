import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _ from "lodash";

import {
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import ButtonLoader from "../elements/ButtonLoader";

import ModalComp from "../elements/ModalComp";
import toast from "react-hot-toast";

import Refresh from "../img/refresh.svg";
import Notify from "../components/Notify";
import DeveloperApi from "../api/DeveloperApi";
import LeadApi from "../api/LeadApi";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 230,
    },
  },
};

const allowedFiles = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/png",
  "application/pdf",
];

const schema = yup.object().shape({
  devname: yup.string().required("You must enter your name"),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("You must enter your email"),
  devcv: yup.mixed().required("CV/Resume is required"),
  jobdes: yup.string().required("This field is required"),
  captcha: yup.string().required("Captcha is required"),
});

const defaultValues = {
  devname: "",
  email: "",
  devcv: "",
  jobdes: "",
  captcha: "",
};

export default function SubmitYourCV(props) {
  const { register, control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const [process, setProcess] = useState(false);

  const [cvName, setCvName] = useState("");
  const [cvFile, setCvFile] = useState();
  const [newText, setNewText] = useState("");
  const [devDeptList, setDevDeptList] = useState({});
  const [devRoleList, setDevRoleList] = useState({});
  const [devDept, setDevDept] = useState([]);
  const [devRole, setDevRole] = useState([]);
  const [cvError, setCvError] = useState("");
  const [deptId, setDeptId] = useState([]);
  const [roleId, setRoleId] = useState([]);

  const genarateRandomText = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setNewText(result);
    // console.log("TXT : ", result);
  };

  const getDeptAPI = () => {
    DeveloperApi.departments()
      .then((resp) => {
        setDevDeptList(resp.departments);
      })
      .catch((_err) => {
        console.table(_err);
        setDevDeptList({});
      });
  };

  const getRoleAPI = () => {
    DeveloperApi.roles()
      .then((resp) => {
        setDevRoleList(resp.roles);
        console.log(resp);
      })
      .catch((_err) => {
        console.table(_err);
      });
  };

  const handleDepChange = (e) => {
    let deptValue = e.target.value;
    setDevDept(
      // On autofill we get a stringified value.
      typeof deptValue === "string" ? deptValue.split(",") : deptValue
    );
    // setDevDept(e.target.value);
  };

  const handleRoleChange = (e) => {
    let deptValue = e.target.value;
    setDevRole(
      // On autofill we get a stringified value.
      typeof deptValue === "string" ? deptValue.split(",") : deptValue
    );
    // console.log(e.target.value);
    // setDevRole(e.target.value);
  };

  const handleCvUpload = (e) => {
    if (e.target.files == undefined || e.target.files.length < 1) {
      setCvError("Please select a file");
      setCvFile({});
      return false;
    }

    const cv = e.target.files[0];
    if (!allowedFiles.includes(cv.type)) {
      setCvError(
        "Please select a valid file. JPG, PNG, DOC, DOCX files allowed"
      );
      setCvFile({});
      return false;
    }
    setCvError("");
    setCvFile(cv);
    setCvName(cv.name);
    return true;
  };

  const careerDataApi = (email, name, dept, role, des, devcv) => {
    setProcess(true);
    LeadApi.careerLead(email, name, dept, role, des, devcv)
      .then((resp) => {
        console.table(resp);
        let d = resp.message;
        toast.success(d);
        setProcess(false);
        props.handleClose();
        refreshCaptcha(5);
      })
      .catch((_err) => {
        let x = _err.data.errors.email || _err.data.errors.resume;
        console.log("error msg: ", x);
        toast.error(x);
        setProcess(false);
        console.log(_err);
      });
  };

  const onSubmit = (data) => {
    if (newText == data.captcha) {
      // let dep = parseInt(devDept);
      // let rol = parseInt(devRole);
      careerDataApi(
        data.email,
        data.devname,
        deptId,
        roleId,
        data.jobdes,
        cvFile
      );
    } else {
      toast.error("Invalid Captcha... Try Again :)");
      refreshCaptcha(5);
    }
  };

  const refreshCaptcha = () => {
    genarateRandomText(5);
  };

  const handleCheckDept = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setDeptId([...deptId, value]);
    } else {
      setDeptId(deptId.filter((item) => item !== value));
    }
    console.log(deptId);
  };

  const handleCheckRole = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setRoleId([...roleId, value]);
    } else {
      setRoleId(roleId.filter((item) => item !== value));
    }
    console.log(roleId);
  };

  useEffect(() => {
    getDeptAPI();
    getRoleAPI();
    genarateRandomText(5);
  }, []);

  return (
    <>
      <ModalComp
        show={props.show}
        handleClose={props.handleClose}
        modalSize="lg"
        modalClass="emailpop cvpopup"
        modalTitle="Job Seeker, Job Finder Submit Your CV"
      >
        <div className="cv_form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6">
                <Controller
                  name="devname"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Full Name"
                      autoFocus
                      type="text"
                      error={!!errors.devname}
                      helperText={errors?.devname?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="col-lg-6">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      label="Email Address"
                      type="email"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <Controller
                  name="deaprtment"
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={{ width: 230 }}>
                      <InputLabel id="deptlabel">Select Department</InputLabel>
                      <Select
                        {...field}
                        labelId="deptlabel"
                        multiple
                        value={devDept}
                        onChange={handleDepChange}
                        input={<OutlinedInput label="Select Department" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        fullWidth
                      >
                        {devDeptList &&
                          Object.entries(devDeptList).map((dept, i) => {
                            return (
                              // <MenuItem key={`deps-${i + 100}`} value={dept[0]}>
                              //   {dept[1]}
                              // </MenuItem>
                              <MenuItem key={`deps-${i + 100}`} value={dept[1]}>
                                <Checkbox
                                  value={dept[0]}
                                  checked={devDept.indexOf(dept[1]) > -1}
                                  onChange={handleCheckDept}
                                />
                                <ListItemText primary={dept[1]} />
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                />

                {/* <Controller
                  name="deaprtment"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="deppt-label">
                        Select Department
                      </InputLabel>
                      <Select
                        {...field}
                        labelId="deppt-label"
                        label="Select Department"
                        value={devDept}
                        onChange={handleDepChange}
                        // error={!!errors.deaprtment}
                        // helperText={errors?.deaprtment?.message}
                        required
                      >
                        {devDeptList &&
                          Object.entries(devDeptList).map((dept, i) => {
                            return (
                              <MenuItem key={`deps-${i + 100}`} value={dept[0]}>
                                {dept[1]}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                /> */}
              </div>
              <div className="col-lg-4">
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={{ width: 230 }}>
                      <InputLabel id="rolelabel">Select Role</InputLabel>
                      <Select
                        {...field}
                        labelId="rolelabel"
                        multiple
                        value={devRole}
                        onChange={handleRoleChange}
                        input={<OutlinedInput label="Select Role" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        fullWidth
                      >
                        {devRoleList &&
                          Object.entries(devRoleList).map((rol, i) => {
                            return (
                              // <MenuItem key={`deps-${i + 100}`} value={rol[0]}>
                              //   {rol[1]}
                              // </MenuItem>
                              <MenuItem key={`deps-${i + 1110}`} value={rol[1]}>
                                <Checkbox
                                  value={rol[0]}
                                  checked={devRole.indexOf(rol[1]) > -1}
                                  onChange={handleCheckRole}
                                />
                                <ListItemText primary={rol[1]} />
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                />

                {/* <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="deppt-label">Select Role</InputLabel>
                      <Select
                        {...field}
                        labelId="deppt-label"
                        name="role"
                        label="Select Role"
                        value={devRole}
                        onChange={handleRoleChange}
                        // error={!!errors.role}
                        // helperText={errors?.role?.message}
                        required
                      >
                        {devRoleList &&
                          Object.entries(devRoleList).map((rol, i) => {
                            return (
                              <MenuItem key={`deps-${i + 1200}`} value={rol[0]}>
                                {rol[1]}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                /> */}
              </div>
              <div className="col-lg-4">
                <div className="input-group">
                  <div className="submit_cv">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Upload Your CV"
                      value={cvName}
                      readOnly
                    />
                    <input
                      type="file"
                      id="cvfile"
                      className="form-control uploadfile"
                      onChange={handleCvUpload}
                      accept="image/jpeg,image/gif,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                    <span className="error">{cvError}</span>
                    {/* <input
                      type="file"
                      id="cvfile"
                      className="form-control uploadfile"
                      accept="image/jpeg,image/gif,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      {...register("devcv", {
                        onChange: (e) => {
                          let f = e.target.files[0];
                          setCvFile(f);
                          setCvName(f.name);
                        },
                        required: true,
                      })}
                    />
                    {errors.devcv && (
                      <span className="error">Your CV is required</span>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Controller
                  name="jobdes"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      multiline
                      rows={4}
                      error={!!errors.jobdes}
                      helperText={errors?.jobdes?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="input-group">
                  <div className="captcha">
                    <label>Captcha*</label>
                    <div className="captcha_box">
                      <input
                        type="text"
                        className="captchaimg"
                        value={newText}
                        readOnly
                      />
                      <input
                        type="text"
                        placeholder="Enter Captcha.."
                        className="captchaInput form-control"
                        {...register("captcha", {
                          required: true,
                        })}
                      />
                      <button
                        type="button"
                        onClick={refreshCaptcha}
                        className="captcha_refresh"
                      >
                        <img src={Refresh} alt="icon" />
                      </button>
                    </div>
                    {errors.captcha && (
                      <span className="error">Captcha is required</span>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="button button-mat btn--7"
                    disabled={
                      _.isEmpty(dirtyFields) ||
                      !isValid ||
                      process ||
                      devDept == "" ||
                      devRole == ""
                    }
                  >
                    <ButtonLoader status={process} btnValue="Submit" />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalComp>

      <Notify />
    </>
  );
}
