import { Link } from "react-router-dom";
import arrow from "../img/arrow-wh.svg";
import amazonicon from "../img/amazonicon.svg";
import googlicon from "../img/googlicon.svg";
import appleicon from "../img/appleicon.svg";
import wiproicon from "../img/wiproicon.svg";
import bannerImgNew from "../img/herosec_img_new.png";

function Homebanner() {
  return (
    <div className="mainbanner">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="bannercnt">
              <h1>
                Hire <span className="redtext">Dedicated</span> <br /> resources
              </h1>
              <p>
                Providing you with top-quality teams to <br /> create and
                implement your vision.
              </p>
              <div className="hiredevbuttonsec">
                <Link className="button button-mat btn--7 " to="/searchdev">
                  Hire Now <img src={arrow} alt="img" />
                </Link>
              </div>

              <div className="trusted">
                <h2>TRUSTED BY</h2>
                <ul>
                  <li>
                    <Link to="/">
                      <img src={amazonicon} alt="img" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={googlicon} alt="img" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={appleicon} alt="img" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={wiproicon} alt="img" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={amazonicon} alt="img" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={googlicon} alt="img" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={appleicon} alt="img" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={wiproicon} alt="img" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="bannerimg">
              <div className="wave">
                {/* <button className="wave__btn" type="button"></button> */}
                <div className="wave__container">
                  <div className="wave__circle"></div>
                  <div className="wave__circle"></div>
                  <div className="wave__circle"></div>
                </div>
              </div>
              <div className="updatetwitimg">
                <img src={bannerImgNew} alt="banner img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homebanner;
