import DeveloperApi from "../api/DeveloperApi";
import { useEffect, useState } from "react";
import Developerlistcomp from "./Developerlistcomp";
import Candidatematch from "./Candidatematch";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Loader from "./Loader";
import NotDataFound from "./NotDataFound";

function Developerresult(props) {
  const [dept, setDept] = useState("");
  const [role, setRole] = useState("");
  const [exp, setExp] = useState("");
  const [xnum, setXnum] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [devs, setDevs] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  // const [emptyText, setEmptyText] = useState("hidebtn");

  const getDevs = (filter = "") => {
    setLoading(true);
    DeveloperApi.list(filter)
      .then((resp) => {
        if (resp.status && resp.developers) {
          setDevs(resp.developers);
          setLoading(false);
          if (resp.developers.length > 0) {
            setXnum(true);
          }
        } else {
          setDevs({});
          console.log("Developer Not Available");
        }
      })
      .catch((_err) => {
        console.table(_err);
      });
  };

  const getDepartment = () => {
    DeveloperApi.departments()
      .then((resp) => {
        if (resp.status && resp.departments) {
          setDeptList(resp.departments);
          setLoading(false);
          // console.log("deptList", deptList);
        } else {
          setDeptList({});
          console.log("Department Not Available");
        }
      })
      .catch((_err) => {
        console.table(_err);
      });
  };

  const handleDepChange = (e) => {
    if (e.target.value !== "") {
      setDept(e.target.value);
      getRole(e.target.value);
      // setLoading(true);
    } else {
      setDept("");
    }
    setRole("");
    setRoleList({});
  };

  const getRole = (d) => {
    if (d && d !== "") {
      DeveloperApi.roles(d)
        .then((resp) => {
          if (resp.status && resp.roles) {
            setRoleList(resp.roles);
            // setLoading(false);
          } else {
            setRoleList({});
            console.log("Roles Not Available");
          }
        })
        .catch((_err) => {
          console.table(_err);
        });
    } else {
      setRoleList({});
    }
    setRole("");
  };

  useEffect(() => {
    getDevs();
    getDepartment();
    getRole();
  }, []);

  const filterDev = () => {
    let q = "";
    if (dept !== "") {
      q = `?department=${dept}`;
    }
    if (role !== "") {
      if (q.length > 0) {
        q = `${q}&category=${role}`;
      } else {
        q = `?category=${role}`;
      }
    }
    if (exp !== "") {
      if (q.length > 0) {
        q = `${q}&experience=${exp}`;
      } else {
        q = `?experience=${exp}`;
      }
    }
    if (q.length > 0) {
      getDevs(q);
      if (dept !== "") {
        DeveloperApi.departments(`?department=${dept}`);
      }
      if (role) {
        DeveloperApi.roles(role);
      }
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="searchform">
        <div className="container">
          <div className="devform">
            <ul>
              <li>
                <FormControl fullWidth>
                  <InputLabel id="dept-label">Select Department</InputLabel>
                  <Select
                    labelId="dept-label"
                    label="Select Department"
                    value={dept}
                    onChange={handleDepChange}
                  >
                    {deptList &&
                      Object.entries(deptList).map((dev, i) => {
                        return (
                          <MenuItem key={`deps-${i + 100}`} value={dev[0]}>
                            {dev[1]}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </li>
              <li
                className={dept === "" || dept === undefined ? "disabled" : ""}
              >
                <FormControl fullWidth>
                  <InputLabel id="role-label">Select Role</InputLabel>
                  <Select
                    labelId="role-label"
                    label="Select Role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    disabled={dept === "" || dept === undefined}
                  >
                    {roleList &&
                      Object.entries(roleList).map((rl, i) => {
                        return (
                          <MenuItem key={`roles-${i + 100}`} value={rl[0]}>
                            {rl[1]}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </li>
              <li>
                <FormControl fullWidth>
                  <InputLabel id="exp-label">Experience</InputLabel>
                  <Select
                    labelId="exp-label"
                    value={exp}
                    label="Experience"
                    onChange={(e) => setExp(e.target.value)}
                  >
                    <MenuItem value={1}>1 Year</MenuItem>
                    <MenuItem value={2}>2 Year</MenuItem>
                    <MenuItem value={3}>3 Year</MenuItem>
                    <MenuItem value={4}>4 Year</MenuItem>
                    <MenuItem value={5}>5+ Year</MenuItem>
                  </Select>
                </FormControl>
              </li>
            </ul>
            <button
              className="button button-mat btn--7"
              onClick={filterDev}
              disabled={dept == "" && role == "" && exp == ""}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      {xnum ? (
        <div>
          {devs && devs.length !== 0 ? (
            <Developerlistcomp devAPI={devs} />
          ) : (
            <NotDataFound />
          )}
        </div>
      ) : null}

      <Candidatematch />
    </>
  );
}
export default Developerresult;
