import { Link } from "react-router-dom";
import infoicon from "../img/infoicon.png";
import visitsiteicon from "../img/visitsiteicon.png";

function Portfoliolist(props) {
  //   console.log(props.id);

  return (
    <div className="col-md-6 col-lg-4" key={props.id}>
      <div className="portlogosec">
        <div className="portlogoimg">
          <Link to={`/portfolio/${props.porttitle}`}>
            <img src={props.portlogo} alt="portlogotitle" />
          </Link>
          <div className="overlaybox">
            <Link to={`/portfolio/${props.porttitle}`}>
              <img src={infoicon} alt="img" />
            </Link>
            <a className="nav-link" href={props.siteurl}>
              <img src={visitsiteicon} alt="img" />
            </a>
          </div>
        </div>
        <div className="portdesc">
          <h3>
            <Link to={`/portfolio/${props.porttitle}`}>{props.porttitle}</Link>
          </h3>
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
}

export default Portfoliolist;
