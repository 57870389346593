import React from "react";
import DevNot from "../img/DevNotAvailable.png";

export default function NotDataFound() {
  return (
    <div className="not_found">
      <div className="container">
        <img src={DevNot} alt="dev" />
        <h2>Developer Not Available</h2>
      </div>
    </div>
  );
}
