import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import logo from "../img/logo.svg";
import phoneicon from "../img/phoneicon.svg";
import envelop from "../img/envelop.svg";
import SubmitYourCV from "../elements/SubmitYourCV";

export default function Header() {
  const [show, setShow] = useState(false);
  // const [mobMenu, setMobMenu] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleMenu = () => {
    if (window.innerWidth <= 991) {
      var togglebtn = document.getElementById("togglebtn");
      togglebtn.click();
    }
  };

  return (
    <>
      <header>
        <div className="headertop">
          <div className="container">
            <Link className={"logo"} to="/">
              <img src={logo} alt="logo" />
            </Link>

            <div className="headerRight">
              <ul>
                <li>
                  <a href="tel:+91 9983333334">
                    <img src={phoneicon} alt="logo" />
                  </a>
                </li>
                <li>
                  <a href="mailto:contact@itoffshoresolutions.com">
                    <img src={envelop} alt="logo" />
                  </a>
                </li>
              </ul>
              <div className="topbtns">
                <Link to="/searchdev" className="bluebtn">
                  Hire Us
                </Link>
                <button
                  to=""
                  className={`button button-mat btn--7`}
                  onClick={handleShow}
                >
                  Join as a freelancer
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Toggle
            id="togglebtn"
            className={``}
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto menu">
              <NavLink className="nav-link" to="/" onClick={handleMenu}>
                Home
              </NavLink>
              <NavLink
                className="nav-link"
                to="/hiredeveloper"
                onClick={handleMenu}
              >
                Hire Developers
              </NavLink>
              <NavLink
                className="nav-link"
                to="/portfolio"
                onClick={handleMenu}
              >
                Portfolio
              </NavLink>
              <NavLink className="nav-link" to="/aboutus" onClick={handleMenu}>
                About Us
              </NavLink>
              <a
                href="https://itoffshoresolutions.com/blog/"
                className="nav-link"
                onClick={handleMenu}
              >
                Blog
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <SubmitYourCV show={show} handleClose={handleClose} />
    </>
  );
}