
function Approach() {
    return(
        <div className="approachsec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                       <ul>
                            <li>
                                <strong>56+</strong>
                                <span>Technologies Worked</span>
                            </li>
                            <li>
                                <strong>86+</strong>
                                <span>Happy Clients</span>
                            </li>
                            <li>
                                <strong>206+</strong>
                                <span>Projects Completed</span>
                            </li>
                            <li>
                                <strong>120+</strong>
                                <span>Experts in Team</span>
                            </li>
                            <li>
                                <strong>12+</strong>
                                <span>Industries Served</span>
                            </li>

                       </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Approach;