import founderimg from "../img/founderimg.png";
import recruiterimg from "../img/recruiterimg.png";
import getdoteimg from "../img/getdoteimg.svg";
import { NavLink } from "react-router-dom";

function Recruiterfounder() {
    return (
      <div className="recruitsec">
        <div className="container">
          <div className="recdoteright">
            <img className="img-fluid" src={getdoteimg} alt="img" />
          </div>
          <div className="recruitfound">
            <div className="recruitimg">
              <img className="img-fluid" src={founderimg} alt="img" />
            </div>
            <div className="recruitcnt">
              <h2>Are your recruiter/founder</h2>
              <p>
                Understand our Hiring process to effectively and efficiently{" "}
                <br /> identify the best candidates
              </p>
              <div className="finddeveloper">
                <NavLink to="/searchdev">Find Developer</NavLink>
              </div>
            </div>
            <div className="recruitimg">
              <img className="img-fluid" src={recruiterimg} alt="img" />
            </div>
          </div>
        </div>
      </div>
    );

}
export default Recruiterfounder;