import webdevelopmenticon from '../img/webdevelopmenticon.svg';
import appdevelopement from '../img/appdevelopement.svg';
import softwaredevelopment from '../img/softwaredevelopment.svg';
import saasdevelopment from '../img/saasdevelopment.svg';
import seoexpert from '../img/seoexpert.svg';
import digitalmarketing from '../img/digitalmarketing.svg';
// import bluearrow from '../img/bluearrow.svg';
import Servlist from './Servicelist';
function Ourservice() {
    return (
      <div className="ourservsec">
        <div className="container">
          <h2 className="blueheading">
            Our IT offshore <span className="redtext">services</span>
          </h2>
          <p className="servicetitle">
            We specialize in creating web apps that are user-friendly, engaging,
            and optimized for maximum conversion and engagement.
          </p>
          <ul className="servicelist">
            <Servlist
              img={webdevelopmenticon}
              servicetitle={"Web Development"}
              servicedescription={
                "Experienced professionals to create websites that deliver the desired results and exceed expectations using latest technologies and best practices to deliver secure, reliable, and optimized solutions."
              }
            />

            <Servlist
              img={appdevelopement}
              servicetitle={"App Development"}
              servicedescription={
                "Delivering human-centric experiences for your mobile app that are valuable and meaningful to your end-users through a combination of great strategies, sterling UI/UX design, and the latest technologies."
              }
            />

            <Servlist
              img={softwaredevelopment}
              servicetitle={"Software Development"}
              servicedescription={
                "Our talent pool has created softwares for different audiences: customers, business partners, ecommerce users, patients, vendors, interest-based communities."
              }
            />

            <Servlist
              img={saasdevelopment}
              servicetitle={"SAAS Development"}
              servicedescription={
                "Specializing in the development of innovative, secure, and scalable SAAS applications, our developers are well-versed with the latest technologies that help to build a success-driven product."
              }
            />

            <Servlist
              img={seoexpert}
              servicetitle={"SEO Expert"}
              servicedescription={
                "Our SEO experts help you to achieve SEO goals that result in better visitors to your website, increase in search engine visibility & brand value as well as better revenue to the business."
              }
            />

            <Servlist
              img={digitalmarketing}
              servicetitle={"Digital Marketing"}
              servicedescription={
                "Our innovative and creative, strategic digital marketing services, will keep increasing your business’ online brand awareness, and engagements with your prospective customers."
              }
            />
          </ul>
        </div>
      </div>
    );
}
export default Ourservice;