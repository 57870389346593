import React, { useState } from "react";
import HireDevForm from "./HireDevForm";

export default function TeamPriceComp(props) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <div className="pricesec">
        <h2>{props.title}</h2>
        <div className="pricecontent">
          <h3>
            ${props.price} <span>/hour</span>
          </h3>
          <ul>
            {props.abputDev.map((list, j) => {
              return <li key={j + 2111}>{list}</li>;
            })}
          </ul>
          <div className="findcontri">
            <button onClick={handleShow}>Find Developer Now</button>
          </div>
        </div>
      </div>
      <HireDevForm label={props.title} show={show} handleClose={handleClose} />
    </>
  );
}
