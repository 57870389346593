import React, { useState } from "react";
import SubmitYourCV from "./SubmitYourCV";

function Postyourcv() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="postyourcv">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>
                Post Your CV Today and We’ll suggest <br /> you a Freelance Job
              </h2>
              <p className="mb-lg-5">
                Posting your CV is the first step for finding your NEXT BIG
                PROJECT from anywhere
              </p>
              <div>
                <button onClick={handleShow} className="readbtn">
                  POST YOUR CV
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubmitYourCV show={show} handleClose={handleClose} />
    </>
  );
}
export default Postyourcv;
