import React from 'react';
import Approach from "../elements/Approach";
import Corevalue from "../elements/Corevalue";
import Lookgreat from "../elements/Lookgreat";
import Webelive from "../elements/Webelive";
import Clientsay from "../elements/Clientsay";
import Awardwining from '../elements/Awardwining';
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <>
      <Helmet>
        <title>
          Offshore Software Development Company India | web development company
        </title>
        <meta
          name="title"
          content="Offshore Software Development Company India | web development company"
        />
        <meta
          name="description"
          content="itoffshoresolutions - of Offshore Development Company Services, offshore software testing company, ecommerce development service, ecommerce programming solutions in jaipur, all over country etc"
        />
        <meta
          name="keywords"
          content="IT Offshore, IT Offshore, Offshore Company, Offshore Software Developers, Offshore Development Company, offshore it"
        />
      </Helmet>
      <div className="aboutmain">
        <Lookgreat />
        <Approach />
        <Corevalue />
        <Webelive />
        <Clientsay />
        <Awardwining />
      </div>
    </>
  );
}