import React, { useState } from "react";
import HireDevForm from "./HireDevForm";

function Candidatematch() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const getEmailData = localStorage.getItem("lead");

  return (
    <>
      <div className={`candidatematch ${getEmailData ? "hidebtn" : ""}`}>
        <h2>candidates match your search!</h2>
        <h3>Update your plan to connect with them now.</h3>
        <div className="upgrageplanbtn_box">
          <button className="button button-mat btn--7" onClick={handleShow}>
            Request a demo
          </button>
        </div>
      </div>
      <HireDevForm label="request" show={show} handleClose={handleClose} />
    </>
  );
}
export default Candidatematch;
