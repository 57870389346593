import "./App.css";
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import { Routes, Route } from "react-router-dom";

import About from "./pages/About";
import Home from "./pages/Home";
import Searchdev from "./pages/Searchdev";
import Hiredeveloperpage from "./pages/Hiredeveloper";
import Portfolio from "./pages/Portfolio";
import Portfoliodetail from "./pages/Portfoliodetail";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route index path="/" element={<Home />}></Route>
        <Route path="/aboutus" element={<About />}></Route>
        <Route path="/hiredeveloper" element={<Hiredeveloperpage />}></Route>
        <Route path="/searchdev" element={<Searchdev />}></Route>
        <Route path="/portfolio" element={<Portfolio />}></Route>
        <Route path="*" element={<NotFound />}></Route>
        <Route
          path="/portfolio/:portName"
          element={<Portfoliodetail />}
        ></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
