import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _ from "lodash";
import { TextField } from "@mui/material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import ButtonLoader from "./ButtonLoader";
import toast from "react-hot-toast";
import ModalComp from "./ModalComp";
import LeadApi from "../api/LeadApi";
import Refresh from "../img/refresh.svg";

const schema = yup.object().shape({
  name: yup.string().required("You must enter your name"),
  email: yup.string().email().required("You must enter your email"),
  tel: yup.number().required("Phone Number is required"),
  companyname: yup.string().required("Company Name is required"),
  captcha: yup.string().required("Captcha is required"),
});

const defaultValues = {
  name: "",
  email: "",
  tel: "",
  companyname: "",
  captcha: "",
};

export default function HireDevForm(props) {
  const { register, control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const [process, setProcess] = useState(false);

  const [newText, setNewText] = useState("");

  // const getEmailData = localStorage.getItem("lead");

  const getLeadData = (lead, name, tel, cname, label, i) => {
    LeadApi.create(lead, name, tel, cname, label)
      .then((resp) => {
        console.table(resp);
        localStorage.setItem("lead", lead);
        let data = resp.message;
        if (i) {
          emailSuccessMsg(data);
        }
        setProcess(false);
        props.handleClose();
      })
      .catch((_err) => {
        console.log(_err);
        console.log("Error : ", _err.data.message);
        let data = _err.data.message;
        if (i) {
          emailErrorMsg(data);
        }
        setProcess(false);
      });
  };

  const emailSuccessMsg = (msg) => {
    toast.success(msg);
  };
  const emailErrorMsg = (msg) => {
    toast.error(msg);
  };

  const genarateRandomText = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setNewText(result);
  };

  const refreshCaptcha = (e) => {
    e.preventDefault();
    genarateRandomText(5);
  };

  const handleOnSubmit = (data) => {
    console.log(data);
    if (newText === data.captcha) {
      setProcess(true);
      getLeadData(
        data.email,
        data.name,
        data.tel,
        data.companyname,
        props.label,
        true
      );
      // checkUserEmail();
      genarateRandomText(5);
    } else {
      setProcess(true);
      genarateRandomText(5);
      setProcess(false);
      toast.error("Invalid Captcha... Try Again :)");
    }
  };

  // console.log(watch("exampleRequired"));

  useEffect(() => {
    genarateRandomText(5);
  }, []);

  return (
    <ModalComp
      show={props.show}
      handleClose={props.handleClose}
      modalClass="emailpop hireform"
      modalTitle="Get a call back in 24 hours"
      modalSize="lg"
    >
      <div className="hiredev_box">
        <div className="row">
          <div className="col-md-12">
            <div className="cv_form">
              <form onSubmit={handleSubmit(handleOnSubmit)}>
                <div className="row">
                  <div className="col-md-6">
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-24"
                          label="Full Name"
                          autoFocus
                          type="text"
                          error={!!errors.name}
                          helperText={errors?.name?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-24"
                          label="Email Address"
                          type="text"
                          error={!!errors.email}
                          helperText={errors?.email?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Controller
                      control={control}
                      rules={{ validate: matchIsValidTel }}
                      render={({ field, fieldState }) => (
                        <MuiTelInput
                          {...field}
                          defaultCountry="IN"
                          error={!!errors.tel}
                          helperText={
                            fieldState.invalid ? "Phone Number is required" : ""
                          }
                          required
                        />
                      )}
                      name="tel"
                    />
                  </div>
                  <div className="col-md-6">
                    <Controller
                      name="companyname"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-24"
                          label="Company Name"
                          type="text"
                          error={!!errors.companyname}
                          helperText={errors?.companyname?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-group">
                      <div className="captcha">
                        <label>Captcha*</label>
                        <div className="captcha_box">
                          <input
                            type="text"
                            className="captchaimg"
                            value={newText}
                            readOnly
                          />
                          <input
                            type="text"
                            placeholder="Enter Captcha.."
                            className="captchaInput form-control"
                            {...register("captcha", {
                              required: true,
                            })}
                          />
                          <button
                            onClick={refreshCaptcha}
                            className="captcha_refresh"
                          >
                            <img src={Refresh} alt="icon" />
                          </button>
                        </div>
                        {errors.captcha && (
                          <span className="error">This field is required</span>
                        )}
                      </div>
                      <button
                        type="submit"
                        className="button button-mat btn--7"
                        disabled={_.isEmpty(dirtyFields) || !isValid || process}
                      >
                        <ButtonLoader status={process} btnValue="Submit" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ModalComp>
  );
}
