import Host from "./Host";

const config = {
  list: "/developers",
  departments: "/departments",
  roles: "/roles",
};
const emailRegex = /[a-zA-Z0-9-_.]{2,}@[a-zA-Z0-9-_]{3,}.[a-z]{2,3}/i;

class DeveloperApi {
  list(filter = "") {
    const lead = localStorage.getItem("lead");
    let tg;
    if (lead !== undefined && lead !== "" && emailRegex.test(lead)) {
      tg = `${config.list}/${lead}${filter}`;
    } else {
      tg = `${config.list}${filter}`;
    }
    return new Promise((resolve, reject) => {
      Host.get(tg)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((_err) => {
          reject(_err.response);
        });
    });
  }

  departments(deptData = "") {
    return new Promise((resolve, reject) => {
      Host.get(`${config.departments}${deptData}`)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((_err) => {
          reject(_err.response);
        });
    });
  }

  roles(d) {
    let tg;
    if (d !== undefined && d !== "") {
      tg = `${config.roles}/${d}`;
    } else {
      tg = `${config.roles}`;
    }
    return new Promise((resolve, reject) => {
      Host.get(tg)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((_err) => {
          reject(_err.response);
        });
    });
  }
}

export default new DeveloperApi;