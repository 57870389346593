import axios from "axios";
import { API_HOST, API_KEY } from "../config/Constant";
const Host =  axios.create({
  baseURL: API_HOST,
});

Host.defaults.headers.common["Authorization"] = `Bearer ${API_KEY}`;
Host.defaults.headers.common["Accept"] = "application/json";

export default Host;

