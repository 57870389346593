import React, { useState } from "react";
import DeveloperItem from "./DeveloperItem";

function Developerlistcomp(props) {
  return (
    <div className="alldeveloperlisting">
      <div className="container">
        <div className="row">
          {props.devAPI &&
            props.devAPI.map((dev, i) => {
              return (
                <DeveloperItem
                  id={dev.uid}
                  key={i + 50}
                  devName={dev.name}
                  devDept={dev.department}
                  tech={dev.tech_stacks}
                  experience={dev.experience}
                  city={dev.city}
                  country={dev.country}
                  current_company={dev.current_company}
                  latest_education={dev.latest_education}
                  devImg={dev.profile_photo_url}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}
export default Developerlistcomp;
