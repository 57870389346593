import React, { useState, useEffect } from "react";
import companyicon from "../img/companyicon.svg";
import eduicon from "../img/eduicon.svg";
import bagicon from "../img/bagicon.svg";
import mapicon from "../img/mapicon.svg";
import ModalComp from "./ModalComp";
import { Controller, useForm } from "react-hook-form";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import Refresh from "../img/refresh.svg";
// import { toast } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _ from "lodash";
import { TextField } from "@mui/material";
import ButtonLoader from "./ButtonLoader";
import { toast } from "react-hot-toast";
import LeadApi from "../api/LeadApi";

const schema = yup.object().shape({
  name: yup.string().required("You must enter your name"),
  email: yup.string().email().required("You must enter your email"),
  phonenumber: yup.number().required("Phone Number is required"),
  companyname: yup.string().required("Company Name is required"),
  captcha: yup.string().required("Captcha is required"),
});

const defaultValues = {
  name: "",
  email: "",
  phonenumber: "",
  companyname: "",
  captcha: "",
};

const DeveloperItem = (props) => {
  const { register, control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const [process, setProcess] = useState(false);

  const [show, setShow] = useState(false);
  const [newText, setNewText] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getDevHire = (email, name, id, tel, cname) => {
    LeadApi.devHireLead(email, name, id, tel, cname)
      .then((resp) => {
        console.table(resp);
        setProcess(false);
        let msg = resp.message;
        toast.success(msg);
      })
      .catch((_err) => {
        console.table(_err);
      });
  };

  const onSubmitHire = (data) => {
    if (newText == data.captcha) {
      setProcess(true);
      getDevHire(
        data.email,
        data.name,
        props.id,
        data.phonenumber,
        data.companyname
      );
      handleClose();
    } else {
      toast.error("Invalid Captcha... Try Again :)");
    }
  };

  const genarateRandomText = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setNewText(result);
    // console.log("TXT : ", result);
  };

  const refreshCaptcha = (e) => {
    e.preventDefault();
    genarateRandomText(5);
  };

  useEffect(() => {
    genarateRandomText(5);
  }, []);

  return (
    <>
      <div className="col-md-6 col-xl-4">
        <div className="developerdetail">
          <div className="devnameimg">
            <div className="devnamedetail">
              <div className="deveimg">
                <img src={props.devImg} alt="img" />
              </div>
              <div className="devcnt">
                <h2>{props.devName}</h2>
                <h3>
                  {props.devDept &&
                    props.devDept.map((d, i) => {
                      return <span key={i + 111}>{d}</span>;
                    })}
                </h3>
              </div>
            </div>
            {/* <button className="resumebtn">
              <img src={whpageicon} alt="img" />
              Resume
            </button> */}
          </div>
          <div className="experience">
            <img src={bagicon} alt="img" /> {props.experience} years experience
          </div>
          <div className="devqulification">
            {props.tech &&
              props.tech.map((tech, i) => {
                return <span key={i + 21}>{tech}</span>;
              })}
          </div>
          <address>
            <p>
              <span>
                <img src={mapicon} alt="img" />{" "}
              </span>{" "}
              {props.city}, {props.country}
            </p>
            <p>
              <span>
                <img src={companyicon} alt="img" />{" "}
              </span>{" "}
              {props.current_company}(Current)
            </p>
            <p>
              <span>
                <img src={eduicon} alt="img" />{" "}
              </span>{" "}
              {props.latest_education}
            </p>
          </address>
          <button className="hirenowbtn" onClick={handleShow}>
            Hire Now
          </button>
        </div>
        <ModalComp
          show={show}
          handleClose={handleClose}
          modalClass="emailpop hireform"
          modalTitle="Get a call back in 24 hours"
          modalSize="xl"
        >
          <div className="hiredev_box">
            <div className="row">
              <div className="col-lg-4">
                <div className="devdetails">
                  <img src={props.devImg} alt="img" />
                  <h3>{props.devName}</h3>
                  <h5>
                    {props.devDept &&
                      props.devDept.map((d, i) => {
                        return <span key={i + 111}>{d}</span>;
                      })}
                  </h5>
                  <div className="experience">
                    <img src={bagicon} alt="img" /> {props.experience} years
                    experience
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="cv_form">
                  <form onSubmit={handleSubmit(onSubmitHire)}>
                    <div className="row">
                      <div className="col-md-6">
                        <Controller
                          name="name"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              label="Full Name"
                              autoFocus
                              type="text"
                              error={!!errors.name}
                              helperText={errors?.name?.message}
                              variant="outlined"
                              required
                              fullWidth
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-6">
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              label="Email Address"
                              type="text"
                              error={!!errors.email}
                              helperText={errors?.email?.message}
                              variant="outlined"
                              required
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Controller
                          control={control}
                          rules={{ validate: matchIsValidTel }}
                          render={({ field, fieldState }) => (
                            <MuiTelInput
                              {...field}
                              defaultCountry="IN"
                              error={!!errors.phonenumber}
                              helperText={
                                fieldState.invalid
                                  ? "Phone Number is required"
                                  : ""
                              }
                              required
                            />
                          )}
                          name="phonenumber"
                        />
                      </div>
                      <div className="col-md-6">
                        <Controller
                          name="companyname"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              label="Company Name"
                              type="text"
                              error={!!errors.companyname}
                              helperText={errors?.companyname?.message}
                              variant="outlined"
                              required
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-group">
                          <div className="captcha">
                            <label>Captcha*</label>
                            <div className="captcha_box">
                              <input
                                type="text"
                                className="captchaimg"
                                value={newText}
                                readOnly
                              />
                              <input
                                type="text"
                                placeholder="Enter Captcha.."
                                className="captchaInput form-control"
                                {...register("captcha", {
                                  required: true,
                                })}
                              />
                              <button
                                onClick={refreshCaptcha}
                                className="captcha_refresh"
                              >
                                <img src={Refresh} alt="icon" />
                              </button>
                            </div>
                            {errors.captcha && (
                              <span className="error">
                                This field is required
                              </span>
                            )}
                          </div>
                          <button
                            type="submit"
                            className="button button-mat btn--7"
                            disabled={
                              _.isEmpty(dirtyFields) || !isValid || process
                            }
                          >
                            <ButtonLoader status={process} btnValue="Submit" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </ModalComp>
      </div>
    </>
  );
};

export default DeveloperItem;
