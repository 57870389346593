import * as React from "react";
import { Spinner } from "react-bootstrap";

export default function ButtonLoader({ status, btnValue }) {
  return (
    <>
      {status === true ? (
        <>
          <Spinner animation="border" variant="light" />
          &nbsp;&nbsp; {"Processing.."}
        </>
      ) : (
        <>{btnValue}</>
      )}
    </>
  );
}
