import React, { useState } from "react";
import { Link } from "react-router-dom";
import operatorimg from "../img/operatorimg.svg";
import SubmitYourCV from "./SubmitYourCV";
function Hiredevexpert() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="hiredevsec">
        <div className="colorbg">
          <img
            className="img-fluid"
            src="images/findhirecoloredbg.svg"
            alt="img"
          />{" "}
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <h2>
                Find & Hire Expert <span className="redtext">developers?</span>
              </h2>
              <p>
                Hire senior pre-vetted developers with strong technical and
                communication skills at unbeatable prices, ready to work in your
                timezone.
              </p>
              <Link to="/searchdev" className="button button-mat btn--7">
                Hire Now
              </Link>
              {/* <div className="hireemail">
              <input type="text" placeholder="Work e-mail" />
              <button className="hirenow">Hire Now</button>
            </div> */}
              <div className="looking">
                Looking for jobs instead?{" "}
                <button onClick={handleShow}>Submit your CV</button>
              </div>
            </div>
          </div>
        </div>
        <div className="operatorimg">
          <img className="img-fluid" src={operatorimg} alt="img" />
        </div>
      </div>
      <SubmitYourCV show={show} handleClose={handleClose} />
    </>
  );
}
export default Hiredevexpert;
