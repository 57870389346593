import React from "react";
import NotFoundImg from "../img/404.png";

export default function NotFound() {
  return (
    <div className="error_sec">
      <div className="container">
        <div className="error_img">
          <img src={NotFoundImg} alt="404" className="img-fluid" />
        </div>
      </div>
    </div>
  );
}
