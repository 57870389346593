// import Innerbanner from '../elements/Innerbanner';
import Awardwining from '../elements/Awardwining';
import Portfoliolist from "../elements/Portfoliolist";
import Portfoliodetailhead from "../elements/Portfoliodetailhead";
import Overviewproject from "../elements/Overviewproject";
import PortfolioAPI from "../api/PortfolioAPI";
import { useParams } from "react-router-dom";


function Portfoliodetail() {
  const { portName } = useParams();

  const portfolioDetail = PortfolioAPI.find((data) => data.title === portName);

  return (
    <>
      <div className="portfoliodetailmain">
        <Portfoliodetailhead
          title={portfolioDetail.title}
          siteImg={portfolioDetail.detailImg}
        />
        <Overviewproject
          description={portfolioDetail.description}
          techList={portfolioDetail.technology}
          workedList={portfolioDetail.whatwedid}
          siteURL={portfolioDetail.siteURL}
        />

        <div className="portfoliopage">
          <h2>
            Other <span>Projects</span>
          </h2>
          <div className="portfoliosec">
            <div className="container">
              <div className="row">
                {PortfolioAPI.slice(0, 3).map((item, i) => {
                  return (
                    <Portfoliolist
                      key={i + 100}
                      portlogo={item.logo}
                      porttitle={item.title}
                      description={item.description}
                      siteurl={item.siteURL}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Awardwining />
      </div>
    </>
  );
}
export default Portfoliodetail;