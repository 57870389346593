const API_HOST = process.env.REACT_APP_API_HOST;
// const API_HOST =
//   process.env.REACT_APP_API_HOST && process.env.REACT_APP_API_HOST !== ""
//     ? process.env.REACT_APP_API_HOST
//     : "http://api.itoffshoresolutions.com/api";
const API_KEY =
  process.env.REACT_APP_API_KEY && process.env.REACT_APP_API_KEY !== ""
    ? process.env.REACT_APP_API_KEY
    : "";

export { API_HOST, API_KEY };
