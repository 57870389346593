import React from "react";
import { Modal } from "react-bootstrap";

export default function ModalComp(props) {
  return (
    <div>
      <Modal
        className={`modalNew ${props.modalClass ? props.modalClass : ""}`}
        show={props.show}
        size={`${props.modalSize ? props.modalSize : ""}`}
        onHide={props.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </div>
  );
}
