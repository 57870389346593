import clientimg01 from "../img/clientimg01.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Component } from "react";
import Slider from "react-slick";
import Clientdata from "../elements/Clienttestimonialdata";

export default class SimpleSlider extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: true,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
    };
    return (
      <div className="clientsay">
        <div className="container">
          <h2>What Our Clients Say</h2>
          <div className="clientsayslider">
            <Slider {...settings}>
              <Clientdata
                userimg={clientimg01}
                username={"Deepak Agrwal"}
                userprofile={"CEO at Eatsy"}
                userdetail={
                  "I got my website designed with online marketing plan and the results are wonderful! The team at Future Profilez takes pride in their work; they take it seriously and are highly motivated. They always try to find a solution, and they work above and beyond to keep the customer happy."
                }
              />
              <Clientdata
                userimg={clientimg01}
                username={"Deepak Agrwal"}
                userprofile={"CEO at Eatsy"}
                userdetail={
                  "I got my website designed with online marketing plan and the results are wonderful! The team at Future Profilez takes pride in their work; they take it seriously and are highly motivated. They always try to find a solution, and they work above and beyond to keep the customer happy."
                }
              />
              <Clientdata
                userimg={clientimg01}
                username={"Deepak Agrwal"}
                userprofile={"CEO at Eatsy"}
                userdetail={
                  "I got my website designed with online marketing plan and the results are wonderful! The team at Future Profilez takes pride in their work; they take it seriously and are highly motivated. They always try to find a solution, and they work above and beyond to keep the customer happy."
                }
              />
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
