import Innerbanner from '../elements/Innerbanner';
import Awardwining from '../elements/Awardwining';
import Portfoliolist from "../elements/Portfoliolist";
import PortfolioAPI from "../api/PortfolioAPI";
import { Helmet } from "react-helmet";

function Blogdetail() {
  return (
    <>
      <Helmet>
        <title>
          Offshore Software Development Company India | Offshore IT Services
        </title>
        <meta
          name="title"
          content="Offshore Software Development Company India | Offshore IT Services"
        />
        <meta
          name="description"
          content="We are a top IT Offshore Software Solutions Providers as we have served thousands of satisfied clients from all over the globe for various Offshore Software Development Services.We are a Top IT Offshore Outsourcing Company. Offers Complete Web Development, IoT Services, Online IT Services, provides Mobile App Development, at affordable prices. Talk to us now on +91-9983333334"
        />
        <meta
          name="keywords"
          content="IT Offshore, Offshore Company, Offshore Software Developers, Offshore Development Company, offshore it"
        />
      </Helmet>
      <div>
        <Innerbanner
          innerheadtitle={["Check out Our ", <span> Portfolio</span>]}
          innerheaddesc={[
            "Find out the IT offshore Project we have done So far",
          ]}
        />
        <div className="portfoliopage">
          <h2>
            Shopify <span>Projects</span>
          </h2>
          <div className="portfoliosec">
            <div className="container">
              <div className="row">
                {PortfolioAPI.map((item, i) => {
                  return (
                    <Portfoliolist
                      key={i + 10}
                      portlogo={item.logo}
                      porttitle={item.title}
                      description={item.description}
                      siteurl={item.siteURL}
                    />
                  );
                })}

                {/* <div className="showmore">
                <a href="#">Show more</a>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <Awardwining />
      </div>
    </>
  );
}
export default Blogdetail;