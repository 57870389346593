
import mobileimg from "../img/mobileimg.svg";

function Webelive() {
    return(
        <div className="webelive">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                       <h2 className="blueheading">What We <span className="redtext">Believe</span></h2>
                       <p className="believetitle">We provide comprehensive Flutter app development services for all kinds of app projects ranging across various device and OS platforms. We offer a single stop destination for all your Flutter app development needs.</p>
                    </div>
                    <div className="col-md-12">
                        <div className="believebox">
                            <div className="beliveboxcnt text-md-end">
                                <div className="belivelisting">
                                    <span className="belivenum">01</span>
                                    <h3>Discover</h3>
                                    <p>We plan a series of discussion session to know the exact requirements and discover your needs</p>
                                </div>

                                <div className="belivelisting">
                                    <span className="belivenum">02</span>
                                    <h3>Define</h3>
                                    <p>We work out on a step-to-step strategy to ensure the project leverages best technologies and stays on right</p>
                                </div>

                                <div className="belivelisting">
                                    <span className="belivenum">03</span>
                                    <h3>Design</h3>
                                    <p>In view of the requirements, we design a rich interface with user-centric experiences grounded with research.</p>
                                </div>
                            </div>
                            <div className="believeimg">
                                <img className="img-fluid" src={mobileimg} alt="img" />
                            </div>

                            <div className="beliveboxcnt text-start">
                                <div className="belivelisting">
                                    <span className="belivenum">04</span>
                                    <h3>Develop</h3>
                                    <p>With the base of customers-centric experiences, we develop full-fledged engineering solutions for your business.</p>
                                </div>

                                <div className="belivelisting">
                                    <span className="belivenum">05</span>
                                    <h3>Deploy</h3>
                                    <p>With the step-by-step implementation of the process from testing to release, we ensure a smooth deployment process.</p>
                                </div>

                                <div className="belivelisting">
                                    <span className="belivenum">05</span>
                                    <h3>Deliver</h3>
                                    <p>We deliver a top-notch software ensuring the smooth working and after launch work of maintenance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Webelive;