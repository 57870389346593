import React, { useState } from "react";
import { Link } from "react-router-dom";
import HireDevForm from "./HireDevForm";
import workbestimg01 from "../img/workbestimg01.svg";

function Workbest() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="workbestsec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-5">
              <div className="bestworkimg">
                <img className="img-fluid" src={workbestimg01} alt="img" />
              </div>
            </div>
            <div className="col-md-12 col-lg-7">
              <div className="bestworkbox">
                <h2 className="blueheading">
                  Work only with <span className="redtext">the best</span>
                </h2>
                <p>
                  Our developers are selected through a 6-stage screening
                  process. As a result, our developers have an average of
                  3-years industry experience and are ready to immediately
                  contribute on projects.
                </p>
                <div className="bestworklist">
                  <ul>
                    <li>
                      <Link to="/">Depth in past work</Link>
                    </li>
                    <li>
                      <Link to="/">Communication skills</Link>
                    </li>
                    <li>
                      <Link to="/">Technical abilities</Link>
                    </li>
                    <li>
                      <Link to="/">Problem-solving skills</Link>
                    </li>
                    <li>
                      <Link to="/">Background check</Link>
                    </li>
                    <li>
                      <Link to="/">Consistent performance</Link>
                    </li>
                  </ul>
                </div>
                <div className="getquote">
                  <button onClick={handleShow}>
                    Get a Quote for Your Developer Needs
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HireDevForm label="request" show={show} handleClose={handleClose} />
    </>
  );
}
export default Workbest;
