
import integrityicon from "../img/integrityicon.svg";


function Corevalue() {
    return(
        <div className="corevalue">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                       <h2>Our Core Values</h2>
                       <h4>At IToffshoresolutions, we strive to provide full-cycle mobile, web, and software design and development services in a quick turnaround time. Our solutions have become the hallmark of affordable & functional excellence in the niche of custom enterprise development. Our drive to achieve continuous growth for clients is the fuel that inspires innovation in what we do.</h4>
                       <ul>
                            <li>
                                <img className="img-fluid" src={integrityicon} alt="img" />
                                <h3>Integrity</h3>
                                <p>We regard integrity as a critical core value. We are committed to possess integrity at an organizational level.</p>
                            </li>

                            <li>
                                <img className="img-fluid" src={integrityicon} alt="img" />
                                <h3>Transparency</h3>
                                <p>We know transparency is important because it eliminates the suspicions and anxieties of our customers. </p>
                            </li>

                            <li>
                                <img className="img-fluid" src={integrityicon} alt="img" />
                                <h3>Accountability</h3>
                                <p>Accountability at work is critical. We understand that to carry out a project successfully we need to develop</p>
                            </li>

                            <li>
                                <img className="img-fluid" src={integrityicon} alt="img" />
                                <h3>Process</h3>
                                <p>We have well defined process that serves as the building block of company. Our processes are the.</p>
                            </li>

                            <li>
                                <img className="img-fluid" src={integrityicon} alt="img" />
                                <h3>Excellence</h3>
                                <p>We at Zaigo Infotech, make sure that excellence never takes a backseat because we want to make</p>
                            </li>

                            <li>
                                <img className="img-fluid" src={integrityicon} alt="img" />
                                <h3>Customer Delight</h3>
                                <p>Customer delight is a core value at Zaigo Infotech. We surprise our customers by exceeding their </p>
                            </li>
                       </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Corevalue;