import Host from "./Host";

const config = {
  create: "/lead/create",
  devHireLead: "/lead/create-developer-lead",
  careerLead: "/lead/career-lead",
};

class LeadApi {
  create(email, name, phone_no, company_name, label) {
    return new Promise((resolve, reject) => {
      Host.post(`${config.create}`, {
        email,
        name,
        phone_no,
        company_name,
        label,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((_err) => {
          reject(_err.response);
        });
    });
  }

  devHireLead(email, name, developer_id, phone_no, company_name) {
    return new Promise((resolve, reject) => {
      Host.post(`${config.devHireLead}`, {
        email,
        name,
        developer_id,
        phone_no,
        company_name,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((_err) => {
          reject(_err.response);
        });
    });
  }

  careerLead(email, name, category_id, sub_category_id, description, cvfile) {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("name", name);
    formData.append("category_id", category_id);
    formData.append("sub_category_id", sub_category_id);
    formData.append("description", description);
    formData.append("resume", cvfile);
    return new Promise((resolve, reject) => {
      Host.post(`${config.careerLead}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((_err) => {
          reject(_err.response);
        });
    });
  }
}

export default new LeadApi();
