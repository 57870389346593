import notsatisfi from "../img/notsatisfi.svg";
import hireprocessimg from "../img/hireprocessimg.svg";
import inquireicon from "../img/inquireicon.svg";
import selectcvicon from "../img/selectcvicon.svg";
import takeinterviewicon from "../img/takeinterviewicon.svg";
import resourceicon from "../img/resourceicon.svg";
function Hiredeveloper() {
    return(
        <div className="hiredevelopersec">
            <div className="container">
            <h2 className="blueheading">Hire Developers for <span className="redtext">Your NEXT Project</span></h2>
            <p className="hiredevtitle">Understand our Hiring process to effectively and efficiently identify the best candidates</p>
                <div className="hiredev">
                    <div className="notsatis">
                        <img className="img-fluid" src={notsatisfi} alt="img" />
                        <h2>Not Satisfied</h2>
                        <p>If you are not satisfied with the resource, you are always capable to choose new resource.</p>
                    </div>
                    <div className="hiredevimg">
                        <img className="img-fluid" src={hireprocessimg} alt="img" />
                    </div>

                    <div className="hiredevprocess">
                        <div className="hiredevlist">
                            <div className="devproceeicon">
                                <img className="img-fluid" src={inquireicon} alt="img" />
                            </div>
                            <div className="hiredevdesc">
                                <h2>Inquiry</h2>
                                <p>Tell us in brief about your ideas and needs. Don't worry it's secure and confidential.</p>
                            </div>
                        </div>

                        <div className="hiredevlist">
                            <div className="devproceeicon">
                                <img className="img-fluid" src={selectcvicon} alt="img" />
                            </div>
                            <div className="hiredevdesc">
                                <h2>Select CV</h2>
                                <p>Shortlist candidates which best fit in your needs by viewing their CVs.</p>
                            </div>
                        </div>

                        <div className="hiredevlist">
                            <div className="devproceeicon">
                                <img className="img-fluid" src={takeinterviewicon} alt="img" />
                            </div>
                            <div className="hiredevdesc">
                                <h2>Take interview</h2>
                                <p>Take interview of the selected candidates over a phone or video call.</p>
                            </div>
                        </div>

                        <div className="hiredevlist">
                            <div className="devproceeicon">
                                <img className="img-fluid" src={resourceicon} alt="img" />
                            </div>
                            <div className="hiredevdesc">
                                <h2>Add resource in your team</h2>
                                <p>Based on interview select impressive candidates and add them to your team.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hiredeveloper;