import Hiredevexpert from "../elements/Hiredevexpert";
import Workbest from "../elements/Workbest";
import Teamprice from "../elements/Teamprice";
// import Selecttop from "../elements/Selecttop";
import Devhiretime from "../elements/Devhiretime";
import Benefithiring from "../elements/Benefitofhiring";
import Postyourcv from "../elements/Postyourcv";
import Faq from "../elements/Faq";
import Awardwining from "../elements/Awardwining";
import CompareAnalysis from "../elements/CompareAnalysis";
import { Helmet } from "react-helmet";
function Hiredeveloperpage() {
  return (
    <>
      <Helmet>
        <title>
          Offshore recruitment services in india I Offshore IT Services
        </title>
        <meta
          name="title"
          content="Offshore recruitment services in india I Offshore IT Services"
        />
        <meta
          name="description"
          content="Hire Dedicated offshore development team and offshore web developer in India. We provide you best custom offshore software development services for your requirements which are less in cost and complete tasks on time with expertise contact us for offshore recruitment agencies in india"
        />
        <meta
          name="keywords"
          content="Offshore Outsourcing Company, Offshore Software Development Services, IT Offshore, offshore it, Offshore IT Outsourcing Services"
        />
      </Helmet>
      <div>
        <Hiredevexpert />
        <Workbest />
        <Teamprice />
        {/* <Selecttop /> */}
        <CompareAnalysis />
        <Devhiretime />
        <Benefithiring />
        <Postyourcv />
        <Faq />
        <Awardwining />
      </div>
    </>
  );
}
export default Hiredeveloperpage;
