import React, { useState } from "react";
import jobimg from "../img/jobimg.svg";
import SubmitYourCV from "./SubmitYourCV";
function Submitcv() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <div className="yourcvload">
        <div className="container">
          <div className="col-md-6">
            <div className="jobfinder">
              <h2>
                Job seeker, Job finder <br />
                <span className="redtext">submit your CV</span>
              </h2>
              <p>
                Looking for a Full time/ Part time/ Remote/ Freelancing job,
                join us just drop your cv and our team will connect with you.
              </p>
              <div className="submitcv">
                <button
                  onClick={handleShow}
                  className="button button-mat btn--7"
                >
                  Submit your CV
                </button>
              </div>
            </div>
          </div>
          <div className="jobfinderbg">
            <img className="img-fluid" src={jobimg} alt="img" />
          </div>
        </div>
      </div>
      <SubmitYourCV show={show} handleClose={handleClose} />
    </>
  );
}

export default Submitcv;
