import React from "react";

function Clientdata(props) {
  return (
    <div class="userdata">
      <div className="clientimg">
        <img src={props.userimg} alt="img" />
      </div>
      <h3>{props.username}</h3>
      <h4>{props.userprofile}</h4>
      <p>{props.userdetail}</p>
    </div>
  );
}

export default Clientdata;
