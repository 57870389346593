import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Component } from "react";
import Slider from "react-slick";
import Industlogo from "./Industlogo";
import IndustryIcon1 from "../img/indus_icon1.svg";
import IndustryIcon2 from "../img/indus_icon2.svg";
import IndustryIcon3 from "../img/indus_icon3.svg";
import IndustryIcon4 from "../img/indus_icon4.svg";
import IndustryIcon5 from "../img/indus_icon5.svg";
import IndustryIcon6 from "../img/indus_icon6.svg";
import IndustryIcon7 from "../img/indus_icon7.svg";
import IndustryIcon8 from "../img/indus_icon8.svg";
import IndustryIcon9 from "../img/indus_icon9.svg";
import IndustryIcon10 from "../img/indus_icon10.svg";
import IndustryIcon11 from "../img/indus_icon11.svg";
import IndustryIcon12 from "../img/indus_icon12.svg";

const industryData = [
  {
    icon: IndustryIcon1,
    title: "Real Estate & Property",
  },
  {
    icon: IndustryIcon2,
    title: "On-Demand Solutions",
  },
  {
    icon: IndustryIcon3,
    title: "Social Networking",
  },
  {
    icon: IndustryIcon4,
    title: "Job portal",
  },
  {
    icon: IndustryIcon5,
    title: "Food & Restaurant",
  },
  {
    icon: IndustryIcon6,
    title: "Sports and Yoga",
  },
  {
    icon: IndustryIcon7,
    title: "Event & Tickets",
  },
  {
    icon: IndustryIcon8,
    title: "Marketplace",
  },
  {
    icon: IndustryIcon9,
    title: "Education & eLearning",
  },
  {
    icon: IndustryIcon10,
    title: "Ecommerce & B2B",
  },
  {
    icon: IndustryIcon11,
    title: "Travel & Hospitality",
  },
  {
    icon: IndustryIcon12,
    title: "Finance & Insurance",
  },
];

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      autoplay: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      centerMode: true,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="industries">
        <div className="">
          <h2 className="blueheading mb-3">
            <span className="redtext">Industries</span> we serve{" "}
          </h2>
          <div className="industrieslogo">
            <Slider {...settings}>
              {industryData.map((data, i) => {
                return (
                  <Industlogo
                    key={i + 100}
                    industlogo={data.icon}
                    industitle={data.title}
                  />
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
